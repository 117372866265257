import styled from 'styled-components';

export const LabelledButtonContainer = styled.button`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  width: 100px;
  padding: 0.5rem .9rem;
  ${props => props.absolute && 
  `position: absolute; 
  top: 5px;
  `}

  ${props => props.align &&
  `${props.align}: 1rem;`
  }
  border:none;
  border-radius: 4px;
  background: #2891D5;

  color: #fff;

  &:hover{
    cursor: pointer;
  }

  @media(max-width: 800px) {
    top: -10px;
  }

  
`