import styled from "styled-components";

export const Status = styled.button`
  color: ${props => props.color};
  font-weight: bold;
  font-size: 1rem;

  background: none;
  border: 1px solid ${props => props.color} ;
  border-radius: 3px;
  
  width: 50%;
  margin: .5rem 0;
  padding: .35rem;
  &:hover{
    cursor: pointer;
  }
`

export const UrlText = styled.span`
  font-size: .95rem;
  font-weight: bold;
`

export const TestAgainLink = styled.a`
  font-size: 0.9rem;
  font-weight: bold;
  color: #2891D5;
  &:hover{
    cursor: pointer;
  }
`