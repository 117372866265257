import styled from "styled-components";

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0; 
  right: 0;

  background: rgba(0, 0, 0, 0.7);

  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalContainer = styled.div`
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.25;

  min-width: 350px;
  max-width: 90%;
  padding: 2rem;
  ${props => props.fixedSize && `
  height: 500px;
  width: 500px;
  `}
  ${props => props.bigger && 'width: 600px;'}
  max-height: ${props => props.bigger ? '800px': '550px'};

  position: relative; 
 
  ${props => props.scroll && 
    `
    overflow-y: auto;
    ::-webkit-scrollbar {
      margin-right: 15px;
      width: 5px;
    }
  
    ::-webkit-scrollbar-track {
      border: 1px solid #eee;
      border-radius: 10px;
    }
  
    ::-webkit-scrollbar-thumb {
      background: #2891D5;
      border-radius: 10px;
    }
  `}


  @media(max-height: 800px) {
    max-height: 600px;
    overflow-y: ${props => props.bigger && 'scroll'};
  };

  span{
    padding: .5rem 0;
  }

`

export const OptionButton = styled.button`
  border: none;
  background:  ${props => props.disabled ? '#aaa' : props.confirmation ? '#2891D5': 'red'};
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  width: ${props => props.inGrid ? '100px' : '100%'};

  &:hover{
    cursor: pointer;
  }
`

export const Message = styled.span`
  font-weight: bold;
  font-size: 1rem;
  align-self: center;
`

export const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  margin-top: 10px;
`
