import styled from "styled-components";


export const Button = styled.button`
  margin: 1rem 0;
  padding: 0.5rem;
  width: 100%;

  background: #2991D6;
  border: none;
  border-radius: 0.2rem;
  
  color: #FFF;
  font-size: 1rem;
  

  &:hover {
    cursor: pointer
  }
`
