
class StoragedList {
  static getList() {
    const result = sessionStorage.getItem('@group_list');
    let list;
    try {
      list = JSON.parse(result);
    } catch {
      return false;
    }
    return list;
  }

  static setList(list) {
    sessionStorage.setItem('@group_list', JSON.stringify(list));
  }

  static formatGroup(group) {
    return {
      grupo: group.groupName,
      grupoEmpresa: group.grupoEmpresa,
      empresas: group.empresas,
      imageSarApp: group.imageSarApp,
      apps: group.apps.map(app => {
        return {
          aplicativo: parseInt(app.aplicativo),
          descricao: parseInt(app.aplicativo) === 1 ? 'SarApp' : parseInt(app.aplicativo) === 2 ? 'HKPonto' : 'HKRonda',
          dataValidade: `${app.datavalidade}T00:00:00.000Z`,
        }
      })
    }
  } 

  static deleteList() {
    sessionStorage.removeItem('@group_list');
  }

  static setGroup(group) {
    const currentList = this.getList();

    const formattedGroup = this.formatGroup(group);

    if (currentList) {
      this.setList([...currentList, formattedGroup])
    }
  }

  static updateGroup(group) {
    const currentList = this.getList();

    const formattedGroup = this.formatGroup(group);

    if (currentList) {
      const updatedList = currentList.map(item => {
        if (item.grupoEmpresa === formattedGroup.grupoEmpresa) {
          return formattedGroup;
        }
        return item;
      });

      this.setList(updatedList);
    }
  }

  static formatCompany(company) {
    let urls = [];
    if (company.urlSarApp) {
      urls.push({ aplicativo: '1', descricao: 'SarApp', url: company.urlSarApp })
    }

    if (company.urlHKPonto) {
      urls.push({ aplicativo: '2', descricao: 'HKPonto', url: company.urlHKPonto })
    }

    if (company.urlHKRonda) {
      urls.push({ aplicativo: '3', descricao: 'HKRonda', url: company.urlHKRonda })
    }

    return {
      codigoEmpresa: parseInt(company.codigoempresa),
      nomeFantasia: company.nomefantasia,
      cnpj: company.cnpj,
      razaoSocial: company.razaosocial,
      logo: company.logo,
      urls: urls
    }
  }

  static setCompany(company) {
    const currentList = this.getList();

    const formattedCompany = this.formatCompany(company);

    if (currentList) {

      const updatedList = currentList.map(item => {
        if (parseInt(item.grupoEmpresa) === parseInt(company.grupoempresa)) {
          return { ...item, empresas: item.empresas.concat(formattedCompany) };
        }
        return item;
      });


      this.setList(updatedList);
    }

  }

  static updateCompany(company) {
    const currentList = this.getList();

    const formattedCompany = this.formatCompany(company);

    if (currentList) {
      let updatedList;
      if (parseInt(company.grupoempresa) === parseInt(company.originalGroup)) {
        updatedList = currentList.map(item => {
          if (parseInt(item.grupoEmpresa) === parseInt(company.originalGroup)) {
            const updatedCompanies = item.empresas.map(empresa => {
              if (parseInt(empresa.codigoEmpresa) === parseInt(company.codigoempresa)) {
                return formattedCompany;
              }
              return empresa;
            })

            return { ...item, empresas: updatedCompanies };
          }
          return item;
        });
      } else {
        updatedList = this.changeCompanyGroup(formattedCompany, company.grupoempresa, company.originalGroup);
      }


      this.setList(updatedList);
    }
  }

  static changeCompanyGroup(company, newGroup, originalGroup) {
    const currentList = this.getList();

    let updatedList = currentList.map(item => {
      if (parseInt(item.grupoEmpresa) === parseInt(originalGroup)) {
        return { ...item, empresas: item.empresas.filter(empresa => empresa.codigoEmpresa !== company.codigoEmpresa) }
      } else if (parseInt(item.grupoEmpresa) === parseInt(newGroup)) {
        return { ...item, empresas: item.empresas.concat(company) }
      }
      return item;
    })

    return updatedList;

  }

  static deleteApp(app, group) {
    const currentList = this.getList();

    let updatedList = currentList.map(item => {
      if (parseInt(item.grupoEmpresa) === parseInt(group)) {
        let updatedApps = item.apps.filter(appItem => appItem.aplicativo !== parseInt(app));
        return { ...item, apps: updatedApps };
      }
      return item;
    })

    this.setList(updatedList);
  }
}


export default StoragedList;