import styled from "styled-components"

export const Header = styled.div`
  width: 100%;
  height: 50px;  
  padding: 0 1rem;
  background: ${props => props.inProduction ? '#2891D5' : 'red'};
  display: flex;
  align-items: center;
  justify-content: ${props => props.center ? 'center' : 'space-between'};
  position: fixed;
  top: 0;
  z-index: 1;
`

export const Title = styled.h1`
  color: #fff;
  font-size: 1.125em;
  font-weight: bold;
  &:hover{
    cursor: pointer;
  }
`

export const IconLink = styled.button`
  font-size: 0;
  outline: none;
  border:none;
  background: none;
  cursor: pointer;
  &:hover{
    cursor: pointer
  }
`

export const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
`

