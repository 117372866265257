import axios from "axios";

class NetWork{
  static validateUrl(url){
    
    let urlToTest;
    try {
      const splittedUrl = url.split(":");
      if (splittedUrl.length < 2) {
        return false;
      }

      const [protocol, domain, portWithPath] = splittedUrl;
      const port = portWithPath.split("/")[0];
      urlToTest = `${protocol}:${domain}:${port}`
      return urlToTest;
    } catch (error) {
      return false;
    }
    
    
  }

  static async testUrl(urlToTest){
    try {
      await axios(urlToTest, { timeout: 2000 });
      return true;
    } catch {
      return false;
    }
  }
}

export default NetWork;