import styled from "styled-components"


export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;

  background: rgba(255, 255, 255, 0.7)
`