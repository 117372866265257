import styled from "styled-components"

export const CompanyItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${props => !props.firstItem ? 'border-top: 1px solid #ccc;': ''}
  width: 95%;
  padding: 1.5rem 0;

  margin: .5rem 0;

`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;

`

export const List = styled.ul`
  display: flex;
  flex-direction: row;

  @media(max-width: 500px){
    flex-direction: column;
  }
`

export const ListItem = styled.li`
  padding: .5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .9rem;
  margin-right: .5rem;

`


export const IconLink = styled.a`
  cursor: pointer;
`


export const Row = styled.div`
  display: flex;
  gap: 8px;
`
