import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import NetWork from "../../utils/network";
import { UrlTestButtonContainer } from "./styles";

function UrlTestButton({url, width }){
  
  const [isLoading, setIsLoading] = useState(false);
  const [urlResponse, setUrlResponse] = useState(null);
  const [isStatusVisible, setIsStatusVisible] = useState(false);

  const handleStatusClick = async () => {
    setIsLoading(true);

    const urlToTest = NetWork.validateUrl(url);
    
    if(!urlToTest){
      setUrlResponse('Inválida');
      setIsStatusVisible(true);
      setIsLoading(false);
      return;
    }
    
    try{
      const isOnline = await NetWork.testUrl(urlToTest);
      
      setIsStatusVisible(true);
      if(isOnline){
        setUrlResponse('Online');
        return 0;
      }else{
        setUrlResponse('Offline');
        return 1;
      }

    }finally{
      setIsLoading(false);
    }
    
    
  }
  return(
    <UrlTestButtonContainer 
        onClick={handleStatusClick}
        disabled={isStatusVisible}
        width={width}
        color={
          !isStatusVisible ? 
          '#2891D5': 
          (urlResponse === 'Online' ? 
            '#25a18e': 
            (urlResponse === 'Offline' ? 
              'red': '#f4a261')
          )
        }
      >
        {isLoading ?
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={10}
            width={20} 
          /> 
        : (isStatusVisible ? urlResponse : 'Testar')}
    </UrlTestButtonContainer> 
  )
}

export default UrlTestButton;