import styled from "styled-components"


export const ItemContainer = styled.li`
  padding: .9rem 0 .3rem;
  display: flex;
  flex-direction: column;
  border-bottom: .5px solid #ccc;

  .space-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: .9rem;

    @media(max-width: 400px){
      flex-direction: column;
    }
  }

  .row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: .5rem;

    width: 10%;
  }

`

export const ItemText = styled.span`
  font-size: ${props => props.smaller ? '.9rem' : '1rem'};
  @media(max-width: 400px){
    margin: .5rem 0;
  }
`
