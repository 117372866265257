class TestApiUrl{
  static saveUrl(url){
    sessionStorage.setItem('@test_api_url', url);
  }

  static getUrl(){
    return sessionStorage.getItem('@test_api_url');
  }

  static deleteUrl(){
    sessionStorage.removeItem('@test_api_url');
  }
}

export default TestApiUrl;