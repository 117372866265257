import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AuthContext from '../../contexts/authContext';

import LoginForm from './components/loginform';
import { PageContainer } from './style';
import api from '../../services/api';
import SessionExpiredModal from './components/sessionexpiredmodal';
import ApiConfigContext from '../../contexts/apiConfigContext';


function Login() {
  const { signIn, isSessionExpired } = useContext(AuthContext);
  const { apiUrl } = useContext(ApiConfigContext);
  const history = useHistory();
  const [hasWrongDataError, setWrongDataError] = useState(false);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [isSessionExpiredModalVisible, setIsExpiredModalVisible] = useState(false);


  useEffect(() => {
    if (isSessionExpired) {
      showExpiredSessionModal();
    }

  }, []);

  const showExpiredSessionModal = async () => {
    setIsExpiredModalVisible(true);
    await new Promise(resolve => setTimeout(resolve, 2000));
    setIsExpiredModalVisible(false)
  }

  const handleLogin = async (values) => {
    try {
      setIsSubmiting(true);
      const response = await api.directTo(apiUrl).post('/loginApiLicenca', values);
      if (response.data.status === 0) {
        signIn(response.data.token);
        history.push('/');
      } else if (response.data.status === 401) {
        setWrongDataError(true);
        setIsSubmiting(false);
      }
    } catch {
      return;
    }
  }

  return (
    <PageContainer role='container'>
      {isSessionExpiredModalVisible && <SessionExpiredModal />}

      <LoginForm
        hasWrongDataError={hasWrongDataError}
        isSubmitting={isSubmitting}
        handleLogin={handleLogin}
      />
    </PageContainer>
  )
}

export default Login;