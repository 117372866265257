import styled from "styled-components";

export const Button = styled.button`
  color: ${props => props.color};
  font-weight: bold;

  background: none;
  border: 1px solid ${props => props.color} ;
  border-radius: 3px;
  
  min-width: 100px;
  padding: .25rem .5rem;

  display: flex;
  gap: 8px;
  align-items: center;

  span{
    justify-self: start;
  }

  img{
    justify-self: center;
  }

  &:hover{
    cursor: pointer;
  }
`