import { useContext, useEffect, useState } from "react";
import { FiCheck, FiCopy } from "react-icons/fi";
import API from '../../services/requests';
import CloseButton from "../closeButton";
import Loader from "react-loader-spinner";
import Modal from "../modal";
import { ChaveContainer, Container, ContainerLinha, CopyButton, ItemContainer, Lista } from "./styles";

export default function KeysModal({ company, handleClose }){

  const [copiedKey, setCopiedKey] = useState();
  const [listaApps, setListaApps] = useState();
  const [erro, setErro] = useState();
  const [carregando, setCarregando] = useState();
  const [liberacaoCarregando, setLiberacaoCarregando] = useState();


  useEffect(() => {
    carregarChaves();
  }, [])


  async function carregarChaves(){
    setCarregando(true);
    try{
      const resposta = await API.getChaves(company.empresa);
      
      if(resposta.error)
        return setErro("Não foi possível carregar as chaves");
      

      setListaApps(resposta.apps);
    }catch{
      return setErro("Não foi possível carregar as chaves");
    }finally{
      setCarregando(false);
    }
  }




  function copiarChave(value){
    navigator.clipboard.writeText(value);
    setCopiedKey(value);
  }


  function Item({ item }){
    return (
      <ItemContainer>
        <span>{item.aplicativo}</span>
        <ul style={{ width: '100%'}}>
        {item.chaves.map(chave => 
          <ContainerLinha>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4}}>
              <span style={{ fontWeight: 500, textAlign: 'left' }}>{chave.descricao}</span>
              <div style={{ display: 'flex'}}>
                <span>{chave.codigo}</span>
                <CopyButton onClick={() => copiarChave(chave.codigo)}>
                  {copiedKey === chave.codigo ?  <FiCheck size={15} color={'#21AB64'}/> : <FiCopy size={15} color={'rgba(0,0,0,.5'}/>}
                </CopyButton>
              </div>
            </div>

            {chave.emUso ? 
            <span style={{ color: '#2891D5', fontSize: 14}}>Em uso</span>
            :
            <span style={{ color: '#21AB64', fontSize: 14}}>Disponível</span>
            }
          </ContainerLinha>
        )}
        </ul>
      </ItemContainer>
    )
  }


  return (
    <Modal bigger>
      <CloseButton handleClick={handleClose} />

      {carregando && 
      <Loader
        type="ThreeDots"
        color="#2891D5"
        height={10}
        width={50}
      />}
      <Container>
        {!!listaApps && (
          listaApps.length > 0? 
        <>
        <span style={{ fontWeight: 'bold'}}>Chaves para empresa {company.nomeFantasia}</span>

        <Lista>
          {!!listaApps && listaApps.map(i => <Item item={i}/>)}
        </Lista>
        </>: <span>Nenhuma chave encontrada</span>)}

        {!!erro && <span>{erro}</span>}
      </Container>
    </Modal>
  )
}

