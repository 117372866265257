import React from 'react';
import {ItemContainer, ItemText } from './styles';
import ButtonIcon from '../../../../components/buttonicon';

function SelectedApp({ name, date, removeApp, openAppEdition }){

 
  return (
    <ItemContainer>
      
      <div className = "space-row">
        <ItemText>
          {name}
        </ItemText>
        <div>
          <ItemText smaller>
            Essa licença expira em: {date} 
          </ItemText>
        </div>
      </div>

      <div className = "space-row">
        <div className = "row">
          <ButtonIcon
            src = "assets/delete.png"
            onClick =  {removeApp}
            name = 'delete-icon'
          />

          <ButtonIcon
            src = "assets/edit.png"
            onClick = {openAppEdition}
            name = 'edit-icon'
          />
        </div>
      </div>
    </ItemContainer>
  )
}

export default SelectedApp;