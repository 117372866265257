import { useState } from "react"
import requests from "../../../../services/requests"
import * as yup from 'yup';

export default function useFormUserSefip() {
  const [loading, setLoading] = useState(false);
  const [isSuccessModalVisible, setIsSucessModalVisible] = useState(false);
  const [isRequestErrorModalVisible, setIsRequestErrorModalVisible] = useState(false);

  const createUser = async (user, pass, codGrupo, callBack) => {
    try {
      setLoading(true);
      const response = await requests.createUserSefip({ user, codGrupo, pass });
      setLoading(false);

      if (!response.error) {
        if (callBack) {
          callBack();
        }
        return showSuccessModal();
      }
      return showRequestErrorModal(response.message);
    } catch (error) {
      return showRequestErrorModal(error);
    } finally {
      setLoading(false)
    }
  }



  const updatePass = async (user, newPass, callBack) => {
    try {
      setLoading(true);
      const response = await requests.updatePassUserSefip(user, newPass);
      setLoading(false);

      if (!response.error) {
        if (callBack) {
          callBack();
        }
        return showSuccessModal();
      }

      return showRequestErrorModal(response.message);

    } catch (error) {
      return showRequestErrorModal(error);
    } finally {
      setLoading(false);
    }
  }



  const disableUser = async (user, callBack) => {
    try {
      setLoading(true);
      const response = await requests.disableEnableUser(user.user, 'Disable');
      setLoading(false);

      if (!response.error) {
        if (callBack) {
          callBack();
        }
        return showSuccessModal();
      }

      return showRequestErrorModal(response.message);

    } catch (error) {
      return showRequestErrorModal(error);
    } finally {
      setLoading(false);
    }
  }


  const enableUser = async (user, callBack) => {
    try {
      setLoading(true);
      const response = await requests.disableEnableUser(user.user, 'Enable');
      setLoading(false);

      if (!response.error) {
        if (callBack) {
          callBack();
        }
        return showSuccessModal();
      }

      return showRequestErrorModal(response.message);

    } catch (error) {
      return showRequestErrorModal(error);
    } finally {
      setLoading(false);
    }
  }



  async function showSuccessModal(message) {
    setIsSucessModalVisible({ show: true, message });
    await new Promise(resolve => setTimeout(resolve, 2000));
    setIsSucessModalVisible({ show: false });
  }

  async function showRequestErrorModal(message) {
    setIsRequestErrorModalVisible({ show: true, message });
    await new Promise(resolve => setTimeout(resolve, 4000));
    setIsRequestErrorModalVisible({ show: false });
  }



  const userSchema = yup.object().shape({
    userName: yup
      .string()
      .required("Campo não pode ficar vazio."),
    pass: yup
      .string()
      .required("Campo não pode ficar vazio."),

  })

  return {
    createUser,
    updatePass,
    disableUser,
    enableUser,
    loading,
    isSuccessModalVisible,
    isRequestErrorModalVisible,
    showSuccessModal,
    showRequestErrorModal,
    userSchema
  }

}