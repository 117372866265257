import React from "react"
import { Button } from "./styles"


function MainButton({ children, onClick, name }){
  return (
    <Button 
      onClick = {onClick}
      aria-label = {name}
    >
      {children}
    </Button>
  )
}

export default MainButton;