import styled, { css } from "styled-components"
import { Form, Field } from 'formik';

const formFieldCss = css`
  display: ${props => props.hidden ? 'none' : 'flex'};
  margin: .5rem 0;
  padding: ${props => props.as === 'select' ? '.3rem' : '.5rem'};
  border: 1px solid #ddd;
  height: ${props => props.bigger ? '3rem' : '2rem'};
  font-size:${props => props.bigger ? '.9rem' : '.8rem'};
  width: 100%;

  &:focus {
    outline: none !important;
  }
  
  @media(max-width: 900px) {
    width: 100%;
  }
`

export const EnableUserLabel = styled.span`
  color: #57cc99;
  font-size: .9rem;
  cursor: pointer;
  transition: all .3s;
  :hover{
    font-weight: bold;
  }
`

export const DisableUserLabel = styled.span`
  color: #bc4749;
  font-size: .9rem;
  cursor: pointer;
  transition: all .1s ease-in;
  :hover{
    font-weight: bold;
  }
`

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`


export const Button = styled.button.attrs(props => ({
  type: props.type
}))`

  padding: 0.5rem;
  width: 200px;
  background: #73bfb8;
  border: none;
  border-radius: 0.2rem;
  align-self:${props => props.align};
  color: #FFF;

  &:hover {
    cursor: pointer;
  };
`

export const SignUpFormikField = styled(Field)`
  ${formFieldCss};
`


export const SignUpFormFieldLabel = styled.label.attrs(props => ({
  htmlFor: props.htmlFor
}))`
  font-weight: bold;
  font-size: ${props => props.bigger ? '.875rem' : '.75rem'};
  align-self: ${props => props.isInsideARow ? 'center' : 'flex-start'};
`


export const CompanyFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  align-self: center;
  position: relative;
  /* padding: 2rem 1rem; */
  
  > label {
    margin-top: 1rem;
  }
  

  @media(max-width: 950px) {
    
    padding: 2rem 0;
    width: 100%;
  }
`