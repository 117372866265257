import React from "react"
import { ItemName, OptionsContainer } from "../../styles";
import { GroupItemContainer } from "./styles";
import ButtonIcon from '../../../../components/buttonicon'
 
function GroupItem(props){
  return(
    <GroupItemContainer
      role="listitem"
    >

      <ItemName bold>
        {props.group.grupo}
      </ItemName>
      
      <OptionsContainer
        columns = {2}
      >

        <ButtonIcon
          src = "assets/edit.png" 
          onClick = {() => props.handleEditClick(props.group.id)}
          name = {`edit-button-${props.group.id}`}
        />
        
        <ButtonIcon
          src = {`assets/${props.group.isCompaniesDisplayed ? 'up-arrow' : 'down-arrow'}.png`} 
          onClick = {() => props.handleDropDownClick(props.group.id)}
          role = 'button'
          name = {`arrow-button-${props.group.grupoEmpresa}`}
        />
        
      </OptionsContainer>
      
    </GroupItemContainer>
  )
}

export default GroupItem;