import styled from "styled-components"
import { Form, Field } from 'formik';



export const LoginFormContainer = styled(Form)`
  height: 25rem;
  max-width: 95%;
  padding: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border: .5px solid #eee;
  border-radius: 5px;
  background: #fff;

  @media(max-width: 800px) {
    border: none;
    width: 400px;
  }
`

export const Logo = styled.img`
  margin-bottom: 2rem;
  width: 250px;
  align-self: center;
`


export const LoginFormField = styled(Field).attrs(props => ({
  type: props.type,
  name: props.name,
  onChange: props.onChange,
  haserrors: props.haserrors
}))`
  border: 1px solid ${props => props.haserrors ? 'red' : '#ccc'};
  border-radius: 5px;
  height: 3rem;
  width: 300px;
  margin: 1rem 0 ${props => props.haserrors ? '0.2rem' : '2rem'};
  padding: .5rem;

  @media(max-width: 800px) {
    width: 100%;
  }

`

export const LoginFormFieldLabel = styled.label.attrs(props => ({
  htmlFor: props.htmlFor
}))`
  font-weight: bold;
  text-align: left;
`

export const Button = styled.button.attrs(props => ({
  type: props.type
}))`
  margin: 1rem 0;
  padding: 0.5rem;
  width: 300px;


  background: #2991D6;
  border: none;
  border-radius: 0.2rem;
  
  font-weight: bold;
  color: #FFF;

  &:hover {
    cursor: pointer
  }

  @media(max-width: 800px) {
    width: 100%;
  }
`

export const Error = styled.div`
  font-size: 0.9rem;
  color: red;
  ${props => props.centered ? 'align-self: center;': null}

  margin-bottom: 1rem;
`