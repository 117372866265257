import styled from "styled-components";

export const WrapperInput = styled.div`
   >div{
      width: 240px;
      align-self: flex-end;
      margin: 10px;
      padding: 4px;
      border: 1px solid #eee;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
   }

   >div>input{
     width: 200px;
     border: none;
     outline: none;
     font-weight: 300;
   }
`