import styled from 'styled-components';

export const GroupItemContainer = styled.div`
  width: 95%;
  padding: 1rem;

  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #ccc;

  @media(max-width: 800px) {
    width: 95%;
  }
`



