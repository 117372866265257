import { useState, createContext, useEffect } from "react";
import StoragedList from "../storage/list";
import TestApiUrl from "../storage/testApiUrl";

const ApiConfigContext = createContext();

export const ApiConfigProvider = ({ children }) => {
  const defaultURL = 'https://api.grupohk.com.br:5003/api/v1/';

  const [apiUrl, setApiUrl] = useState('');
  const [isPointingToDefaultUrl, setIsPointingToDefaultUrl] = useState(true);

  useEffect(() => {
    getApiUrl();
  }, [])

  const getApiUrl = () => {
    const storagedTestApiUrl = TestApiUrl.getUrl();
    if (storagedTestApiUrl) {
      setApiUrl(storagedTestApiUrl);
      setIsPointingToDefaultUrl(false);
    } else {
      setApiUrl(defaultURL);
      setIsPointingToDefaultUrl(true);
    }

  }

  const changeApiUrl = (url) => {
    setApiUrl(url);
    setIsPointingToDefaultUrl(false);

    TestApiUrl.saveUrl(url);
    StoragedList.deleteList();
  }

  const backToDefaultUrl = () => {
    setApiUrl(defaultURL);
    setIsPointingToDefaultUrl(true);

    TestApiUrl.deleteUrl();
    StoragedList.deleteList();
  }

  return (
    <ApiConfigContext.Provider
      value={{
        apiUrl,
        isPointingToDefaultUrl,
        changeApiUrl,
        backToDefaultUrl,
        getApiUrl
      }}
    >
      {children}
    </ApiConfigContext.Provider>
  )
}

export default ApiConfigContext;