import React from 'react';
import Modal from '../../../../components/modal';


function SessionExpiredModal(){
  return(
    <Modal>
      <span>Sua sessão expirou, faça o login novamente.</span>
    </Modal>
  )
}

export default SessionExpiredModal;