
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  HashRouter
} from "react-router-dom";
import Login from "../pages/login";
import Signup from "../pages/signup";
import Header from "../components/header";
import GroupList from "../pages/grouplist";
import { useContext } from "react";
import AuthContext from "../contexts/authContext";
import { FormProvider } from "../contexts/formContext";
import VersaoServicos from "../pages/versaoservicos";



const PrivateRoute = ({ children, ...props }) => {
  const authContext = useContext(AuthContext);
  return (
    <Route {...props}>
      {authContext.logged ? (
        children
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      )}
    </Route>
  )
}

const LoginRoute = ({ children, ...props }) => {
  const authContext = useContext(AuthContext);
  return (
    <Route {...props}>
      {!authContext.logged ? (
        children
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )}
    </Route>
  )
}


function Routes() {
  return (
    <FormProvider>
      <Router>
        <HashRouter>
          <Header />

          <Switch>
            <LoginRoute path="/login">
              <Login />
            </LoginRoute>

            <PrivateRoute path="/lista">
              <GroupList />
            </PrivateRoute>

            <PrivateRoute path="/cadastro">
              <Signup />
            </PrivateRoute>

            <PrivateRoute path="/versoesservicos">
              <VersaoServicos />
            </PrivateRoute>

            <PrivateRoute exact path="/">
              <GroupList />
            </PrivateRoute>

          </Switch>
        </HashRouter>
      </Router>
    </FormProvider>
  );
}

export default Routes;
