class Format {
  static pureDateToDMY(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${(date.getDate() + 1).toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`
    return formattedDate;
  }

  static dateYMDtoDMY(dateString) {
    const splitted = dateString.split('-');
    const formattedDate = `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
    return formattedDate;
  }

  static pureDateToYMD(dateString) {
    const date = new Date(dateString.slice(0, -1));
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}`;
    return formattedDate;
  }

  static asCnpj(value) {
    value = value.replace(/\D/g, "")
    value = value.replace(/^(\d{2})(\d)/, "$1.$2")
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    value = value.replace(/\.(\d{3})(\d)/, ".$1/$2")
    value = value.replace(/(\d{4})(\d)/, "$1-$2")
    return value
  }
}

export default Format;