import React, { useState, useEffect, useContext } from "react";
import ResponseModal from "../../components/responsemodal";
import FormContext from "../../contexts/formContext";
import GroupSignUpForm from "./components/groupsingupform";
import { IoIosClose } from "react-icons/io";
import { useHistory } from "react-router";
import { FormsContainer, Title, FormsArea, PageContainer } from "./styles";
import CloseButton from "../../components/closeButton";

function Signup() {

  const {
    groupToUpdate,
    companyToUpdate,
    clearGroupSelection,
  } = useContext(FormContext);

  const history = useHistory();

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  const [hasANewGroupInsertion, setHasANewGroupInsertion] = useState(false);


  useEffect(() => { 
    return () => {
      clearGroupSelection();
    };
  }, []);


 

  const showModal = async (type) => {

    let setModalVisible;
    if (type === 'success') {
      setModalVisible = (bool) => setIsSuccessModalVisible(bool);
    } else {
      setModalVisible = (bool) => setIsErrorModalVisible(bool);
    }

    setModalVisible(true);
    await new Promise(resolve => setTimeout(resolve, 2000));
    setModalVisible(false);

  }

  const handleNewGroupInsertion = (value) => {
    setHasANewGroupInsertion(value);
  }


  const goBack = () => {
    history.goBack();
  }


  return (
    <PageContainer hiddenOverflow={isSuccessModalVisible}>

      {isSuccessModalVisible &&
        <ResponseModal
          icon='checked'
          response={`Item ${companyToUpdate || groupToUpdate ? 'alterado' : 'cadastrado'} com sucesso.`}
        />
      }

      {isErrorModalVisible &&
        <ResponseModal
          icon='sad'
          response='Algo deu errado. Tente novamente.'
        />
      }

      <FormsContainer>

        
        <CloseButton handleClick={goBack}/>

        <FormsArea>
          <GroupSignUpForm
            groupToUpdate={groupToUpdate}
            showModal={showModal}
            handleNewGroupInsertion={handleNewGroupInsertion}
          />
        </FormsArea>
      </FormsContainer>

    </PageContainer>
  )
}

export default Signup;