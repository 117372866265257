import styled from "styled-components";


export const ClickableLabel = styled.a`
  font-weight: bold;
  font-size: 0.9rem;
  color: #2891D5;
  margin: .5rem 0 0;

  &:hover{
    cursor: pointer;
  }
`

export const Label = styled.label`
  font-weight: bold;
  font-size: 1rem;
  align-self: center;
`

export const UrlInput = styled.input`
  border: 1px solid ${props => props.hasErrors ? 'red':'#ccc'};
  padding: .5rem;
  margin: ${props => props.hasErrors ? '0':'.5rem'} 0 1rem;
  height: 2rem;
  font-size: .9rem;
  width: 100%;
`

export const ErrorMessage = styled.span`
  color: red;
  font-size: .8rem;
`
