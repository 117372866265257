import styled from "styled-components";


export const List = styled.ul`
  margin-bottom: 1rem;
  width: 100%;
`

export const ItemContainer = styled.li`
  padding: 10px;
  border-bottom: 1px solid #eee;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  
  &:hover{
    cursor: pointer;
    background: #eee;
  }
`

export const Label = styled.span`
  font-size: 16px;
`

export const Body = styled.div`
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ::-webkit-scrollbar {
    margin-right: 15px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    border: 1px solid #eee;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2891D5;
    border-radius: 10px;
  }
`

export const Title = styled.h1`
  font-weight: bold;
  margin-bottom: 15px;
`