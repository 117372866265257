import { Button } from './styles';

function OutlineRoundButton({ children, app, onClick }){
  return(
    <Button
      color = {parseInt(app) === 1 ? '#4C7AFB' : parseInt(app) === 2 ? '#e07a5f' : '#9E3636'}
      onClick = {onClick}
    >
      {children}
    </Button>
  )
}

export default OutlineRoundButton;