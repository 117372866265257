import React from "react"
import { ButtonIconContainer } from "./styles";


function ButtonIcon({ onClick, role, name, src}){
  return(
    <ButtonIconContainer 
      onClick = {onClick}
      role = 'button'
    >
      <img
        src = {src}
        width = '14px'
        alt = {name}
      />
    </ButtonIconContainer>
  )
}

export default ButtonIcon;