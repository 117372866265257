import { useContext, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useHistory, useLocation } from 'react-router';
import ApiConfigContext from '../../contexts/apiConfigContext';
import NetWork from '../../utils/network';
import Modal from '../modal';
import { OptionButton } from '../modal/styles';
import { ClickableLabel, ErrorMessage, Label, UrlInput } from './styles';

function ApiConfigurationModal({ closeModal }){

  const { 
    apiUrl, 
    isPointingToDefaultUrl, 
    changeApiUrl, 
    backToDefaultUrl 
  } = useContext(ApiConfigContext);

  const initialValue = isPointingToDefaultUrl ? '' : apiUrl;
  const [urlInputValue, setUrlInputValue] = useState(initialValue);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleInputValueChange = (event) => {
    setUrlInputValue(event.target.value);
  }


  const handleUrlConfigClick = async () => {
    setErrorMessage('');
    setIsLoading(true);
    try{
      const isUrlValid = NetWork.validateUrl(urlInputValue);

      if(!isUrlValid){
        setErrorMessage('URL Inválida');
        return;
      }

      const isUrlOnline = await NetWork.testUrl(`${urlInputValue}/testaapi`);

      if(isUrlOnline){
        changeApiUrl(urlInputValue);
        
        if(location.pathname !== '/')
          history.push("/");

        closeModal();
      }else{
        setErrorMessage('Api offline');
      }
    }catch{
      return;
    }finally{
      setIsLoading(false);
    }
  }

  

  const handleDefaultOptionClick = () => {
    if(!isPointingToDefaultUrl){
      backToDefaultUrl();
      history.push("/");
    }

    closeModal();
  }

  
  return(
    <Modal>

      <Label htmlFor='url-input'>
        Configurar API de teste
      </Label>

      {errorMessage &&
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>
      }
      
      <UrlInput
        value={urlInputValue} 
        onChange={handleInputValueChange}
        hasErrors={errorMessage}
        id='url-input'
      />
      

      <OptionButton 
        confirmation
        disabled={!urlInputValue}
        onClick={handleUrlConfigClick}
      >
        {isLoading ? 
        <Loader
          type="ThreeDots"
          color="#fff"
          height={10}
          width={20}
        />  
        : 'Ok'}
      </OptionButton>

      <ClickableLabel
        onClick={handleDefaultOptionClick} 
      >
        {isPointingToDefaultUrl ? 'Continuar em' : 'Voltar a'} produção
      </ClickableLabel>
    </Modal>
  )
}

export default ApiConfigurationModal;