import { ButtonContainer } from "./style";
import { AiOutlineClose } from 'react-icons/ai';

function CloseButton({ handleClick }){
  return(
    <ButtonContainer onClick={handleClick}>
      <AiOutlineClose size={20} />
    </ButtonContainer>
  )
}

export default CloseButton;