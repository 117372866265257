import React, { useContext, useState } from "react"
import { withRouter, Link } from "react-router-dom";
import { AiFillHome } from 'react-icons/ai';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { BsFillGearFill, BsFillShieldLockFill } from 'react-icons/bs'
import { BiGitMerge } from 'react-icons/bi'


import {
  Header,
  IconLink,
  OptionsContainer,
  Title
} from "./style"
import AuthContext from "../../contexts/authContext";
import ApiConfigContext from "../../contexts/apiConfigContext";
import ApiConfigurationModal from "../apiconfigurationmodal";
import CertificadoConfig from "../certificadosconfig"
import { name, version } from "../../../package.json"

function HeaderComponent(props) {
  const { signOut, logged } = useContext(AuthContext);
  const { isPointingToDefaultUrl, backToDefaultUrl } = useContext(ApiConfigContext);
  const [showSSLConfig, setShowSSLConfig] = useState(false);
  const [showSystemsVersion, setShowSystemsVersion] = useState(false);
  const [isApiConfigurationModalVisible, setIsApiConfigurationModalVisible] = useState(false);

  const goBackToHome = () => {
    props.history.push("/#/home");
  }

  const logout = () => {
    signOut();
    backToDefaultUrl();
  }

  const handleConfigClick = () => {
    setIsApiConfigurationModalVisible(true);
  }



  function openSSLConfig() {
    setShowSSLConfig(true);
  }

  function closeSSLConfig() {
    setShowSSLConfig(false);
  }


  function openSystemVersions() {
    setShowSystemsVersion(true);
  }

  function closeSystemVersions() {
    setShowSystemsVersion(false);
  }



  return (
    <>
      {
        isApiConfigurationModalVisible &&
        <ApiConfigurationModal
          closeModal={() => setIsApiConfigurationModalVisible(false)}
        />
      }


      {
        showSSLConfig && <CertificadoConfig
          closeModal={() => closeSSLConfig(false)}
        />
      }

      <Header
        center={!logged}
        inProduction={isPointingToDefaultUrl}
        role='header'
      >

        {logged &&
          <Link
            to={"/"}
          >
            <AiFillHome
              size={25}
              color='#fff'
            />
          </Link>
        }

        <Title
          onClick={goBackToHome}
        >
          Gerenciador de Apps {`${version}`}
        </Title>

        {logged &&
          <OptionsContainer>
            <Link
              to={"/versoesservicos"}
            >
              <IconLink
                role='link'
                aria-label='configurar SSL'
                onClick={openSystemVersions}
              >
                <BiGitMerge
                  size={25}
                  color='#fff'
                />
              </IconLink>
            </Link>


            <IconLink
              role='link'
              aria-label='configurar SSL'
              onClick={openSSLConfig}
            >
              <BsFillShieldLockFill
                size={25}
                color='#fff'
              />
            </IconLink>

            <IconLink
              role='link'
              aria-label='configurar url da api'
              onClick={handleConfigClick}
            >
              <BsFillGearFill
                size={25}
                color='#fff'
              />
            </IconLink>

            <IconLink
              role='link'
              aria-label='deslogar'
              onClick={logout}
            >
              <RiLogoutBoxRLine
                size={25}
                color='#fff'
              />
            </IconLink>

          </OptionsContainer>
        }
      </Header>
    </>
  );
}

export default withRouter(HeaderComponent);