import React from "react"
import { LabelledButtonContainer } from "./styles";


function LabelledButton(props){
  return(
    <LabelledButtonContainer
      onClick={props.handleClick}
      absolute={props.absolute}
      align={props.align}
    >
      <img 
        src={props.src}
        width='10px'
        alt={'...'}
      />
      <p>{props.label}</p>
    </LabelledButtonContainer>
  )
}

export default LabelledButton;