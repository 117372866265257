import { useContext, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useHistory, useLocation } from 'react-router';
import ApiConfigContext from '../../contexts/apiConfigContext';
import AuthContext from '../../contexts/authContext';
import NetWork from '../../utils/network';
import Modal from '../modal';
import { OptionButton } from '../modal/styles';
import { ClickableLabel, CrtsInput } from './styles';
import Utils from "../../utils"
import api from '../../services/api';
import { setLocale } from 'yup';


function CertificadoConfig({ closeModal }) {

  const { apiUrl } = useContext(ApiConfigContext);
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [crt, setCrt] = useState('');
  const [key, setKey] = useState('');
  const [ca, setCa] = useState('');

  useEffect(() => {
    getCertificates();
  }, [])


  const getCertificates = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

      setIsLoading(true)
      const response = await api.directTo(apiUrl).get("/getCurrentCertificate", config);
      setIsLoading(false)

      if (response.data && response.data.status === 0) {
        const { CRT, CRT_KEY, CA } = response.data.data;
        setCrt(CRT);
        setKey(CRT_KEY);
        setCa(CA);
      } else {
        if (response.data && response.data.status !== 404) {
          alert("Ops... Não foi possível recuperar certificados: " + response.data.message)
        }
      }

    } catch (error) {
      setIsLoading(false)
      alert("Ops... Não foi possível recuperar certificados: " + error)
    }
  }



  const sendCertificates = async () => {
    try {
      if (!crt || !key || !ca) {
        return alert("Preencha todos os campos");
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

      const body = { crt, key, ca };

      setIsLoading(true);
      const response = await api.directTo(apiUrl).post("/saveCertificates", body, config);
      setIsLoading(false);

      const { data } = response;

      if (data.status === 0) {
        alert("Certificado salvo!");
        closeModal();
      } else {
        alert("Ops... Algo deu errado ao salvar o sertificado: " + data.message)
      }

    } catch (error) {
      setIsLoading(false);
      alert("Não foi possível salvar os certificados: " + error)
      throw new Error("Não foi possível salvar os certificados: " + error);
    }
  }




  async function handleCRT(event) {
    setCrt(event.target.value);
  }

  async function handleKey(event) {
    setKey(event.target.value);
  }

  async function handleCA(event) {
    setCa(event.target.value);
  }





  return (
    <Modal>
      <>
        <label>CRT</label>
        <CrtsInput
          value={crt}
          onChange={handleCRT}
          placeholder="CRT"
        />
      </>

      <>
        <label>Private Key</label>
        <CrtsInput
          value={key}
          onChange={handleKey}
          placeholder="Key"
        />
      </>


      <>
        <label>CA</label>
        <CrtsInput
          value={ca}
          onChange={handleCA}
          placeholder="CA"
        />
      </>



      <OptionButton
        confirmation
        disabled={isLoading}
        onClick={sendCertificates}
      >
        {isLoading ? <Loader type="ThreeDots" color="#fff" height={10} width={20} />
          : 'Enviar'}
      </OptionButton>

      <ClickableLabel onClick={closeModal}>
        Fechar
      </ClickableLabel>
    </Modal>
  )
}

export default CertificadoConfig;