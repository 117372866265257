import axios from 'axios';

const api = {
  directTo: (baseURL) => {
    return axios.create({
      baseURL//"http://127.0.0.1:7003/api/v1"
    })
  }
}


export default api;