import styled from "styled-components";

export const ButtonContainer = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 5px;
  right: 0;

  &:hover{
    cursor: pointer;
  }
`