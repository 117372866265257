import styled from "styled-components";

export const CompanyTableContainer = styled.table`
  width: 100%;
  align-self: center;
  margin: 15px 0;
`

export const TableHeadRow = styled.tr`
  border-bottom: 1px solid #ccc;
  @media(max-width: 700px){
    display: none;
  }
`

export const TableRow = styled.tr`
  background: ${props => props.whiteRow ? '#fff' : '#eee'};
  &:hover {
    cursor: pointer;
    background: #ccc;
  }

  > a {
    display: none;
  }

  @media(max-width: 700px){
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 1rem .5rem;

    > a {
      display: block;
    }

  }
`

export const ResponsiveDataContainer = styled.div`
  display: none;

  @media(max-width: 700px){
    display: flex;
    flex-direction: column;
  }

`

export const ResponsiveLabel = styled.p`
  display: none;

  @media(max-width: 700px){
    display: block;
    margin-bottom: 10px;
    ${props => props.bold && 'font-weight: bold'}
  }
`

export const TableHead = styled.th`
  text-align: center;
  font-size: 1rem;
  padding: 15px;
  font-weight: bold;
`


export const DeleteButton = styled.button`
  padding: 2px 8px;
  border: none;
  color: red;
  cursor: pointer;
  background: #fff;
`

export const TableData = styled.td`
  text-align: center;
  padding: 15px;
  font-size: .9rem;
  width: ${props => props.smaller ? '20%' : '40%'};

  

  @media(max-width: 700px){
    display: none;
  }
`



