import styled, { css } from "styled-components"


import { Form, Field } from 'formik';
import InputMask from 'react-input-mask';


const formCss = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  align-self: center;
  position: relative;
  padding: 2rem 3rem;

  

  @media(max-width: 950px) {
    
    padding: 2rem 0;
    width: 100%;
  }
`






const formFieldCss = css`
  display: ${props => props.hidden ? 'none' : 'flex'};
  margin: .5rem 0;
  padding: ${props => props.as === 'select' ? '.3rem' : '.5rem'};
  border: 1px solid #ddd;
  height: ${props => props.bigger ? '3rem' : '2rem'};
  font-size:${props => props.bigger ? '.9rem' : '.8rem'};
  width: 100%;

  &:focus {
    outline: none !important;
  }
  
  @media(max-width: 900px) {
    width: 100%;
  }
`


export const LogoBox = styled.div`
  width: 50px;
  height: 50px;
  border: 1px dashed silver; 
  margin-top: 5px;
  cursor: pointer;
`


export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #2891d6;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23278dd6' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%232688d6' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%232484d6' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23227fd6' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23207bd6' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23227fd6' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%232484d6' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%232688d6' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23278dd6' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%232891d6' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  
  position: relative;

  
  min-height: 100vh;
  padding: 100px 0 22px;
  ${props => props.hiddenOverflow ?
    'overflow: hidden; height: 100vh' : ''};


  @media(max-width: 800px) {
    font-size: 12px;

  }
`

export const FormsContainer = styled.div`
  width: 900px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: -1px 2px 20px -10px #000000; 
  box-shadow: -1px 2px 20px -10px #000000;
  position: relative;

  @media(max-width: 900px) {
    width: 90%;
  }


  .closeButtonCad{
     width: 25px;
     height: 25px;
     position: absolute;
     right: 10px;
     top: 10px;
     background: none;
     border: 1px solid #2991D6;
     border-radius: 25px;
     cursor: pointer;
     justify-content: center;
     align-items: center;
     display: flex;
     padding: 0;
  }

`

export const FormsArea = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 3em 0;
  width: 100%;

  @media(max-width: 1000px) {
    display: flex;
    justify-content: center;
    width: 90%;
  }
`

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: bold;
`

export const ElementWithButtonContainer = styled.div`
  display: flex;
  align-items: center;
  > a, button {
    margin-left: 10px;
  }
`

export const IconButton = styled.a`
  
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.absolute && `
    position: absolute;
    right: 0;
  `}

  &:hover{
    cursor: pointer;
  }
`

export const CompanyFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  align-self: center;
  position: relative;
  /* padding: 2rem 1rem; */
  
  > label {
    margin-top: 1rem;
  }
  

  @media(max-width: 950px) {
    
    padding: 2rem 0;
    width: 100%;
  }
`

export const SignUpForm = styled.div`
  ${formCss}
`

export const SignUpFormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  margin: 0.5rem 0;
  width: 100%;

  @media(max-width: 800px) {
    flex-direction: column-reverse;
  }
`

export const SignUpFormikField = styled(Field)`
  ${formFieldCss};
`

export const SignUpFormField = styled.input`
  ${formFieldCss};
`



export const InputImage = styled.input`
  display: none;
`
export const SectionBGSarApp = styled.div`
`

export const BoxImageGroup = styled.div`
  width: 100%;
  height: ${props => props.wasImageUploaded ? '205px' : '150px'};
  border: 1px dotted #eee; 
  display: flex; 
  justify-content: center;
  align-items: center;
  margin-top: 10px;
 
  > p {
    font-size: 1rem;
  }
`

export const SelectedAppList = styled.ul`
  margin-bottom: 10px;
`


export const PlaceholderSquare = styled.div`
  border: 1px dotted #eee;
  width: 100%;
  height: 150px;
  margin: 10px 0 ${props => props.haserrors ? '.5rem' : ''};
  
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;
`






export const SeparateLine = styled.div`
  width: 90%;
  height: 1px;
  background: #eee;
  align-self: center;
  margin: 20px 0;
`


export const SignUpFormMaskedField = styled(InputMask).attrs(props => ({
  mask: props.mask
}))`
  margin: .5rem 0;
  border: 1px solid #ddd;
  height: 2rem;
`

export const NumberField = styled.input.attrs(props => ({
  type: 'number'
}))`
  ${formFieldCss};
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  };
`


export const SignUpFormFieldLabel = styled.label.attrs(props => ({
  htmlFor: props.htmlFor
}))`
  font-weight: bold;
  font-size: ${props => props.bigger ? '.875rem' : '.75rem'};
  align-self: ${props => props.isInsideARow ? 'center' : 'flex-start'};
`

export const Error = styled.div.attrs(() => ({
  role: 'error'
}))`
  font-size: 0.9rem;
  color: red;

  margin-bottom: 1rem;
`

export const FileUploadButton = styled.label.attrs(props => ({
  htmlFor: props.htmlFor
}))`
  padding: 0.5rem;
  width: fit-content;

  background: #2991D6;
  border: none;
  border-radius: 0.2rem;

  color: #FFF;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
  };

  @media(max-width: 800px) {
    margin-top: 1rem;
  };
  
`

export const RowSection = styled.div`
  display:flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${props => props.margin && `margin: ${props.margin};`}
`

export const GridItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const SimpleGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.template ? props.template : '1fr 1fr'};
  grid-gap: 5%;
  margin: 20px 0 20px;

  @media(max-width: 940px) {
    display: block;
  };
`

export const SpecialInputContainer = styled.div`
  @media(max-width: 940px) {
    margin-bottom: 25px;
  };
`

export const Button = styled.button.attrs(props => ({
  type: props.type
}))`
  margin: 2rem 0 0;
  padding: 0.5rem;
  width: 200px;
  background: #73bfb8;
  border: none;
  border-radius: 0.2rem;
  align-self:${props => props.align};
  color: #FFF;

  &:hover {
    cursor: pointer;
  };
`

export const OutlineButton = styled.button`
  padding: 0.5rem;

  background: #fff;
  border: 1px solid #2991D6;
  border-radius: 0.2rem;
  
  color: #2991D6;

  &:hover {
    cursor: pointer;
  };
`



const cssLink = css`
  color: ${props => props.haserrors ? 'red' : '#2991D6'};
  font-weight: bold;
  font-size: .9rem;  
  margin-top: 10px;
  &:hover {
    cursor: pointer;
  };

  @media(max-width: 940px) {
    margin-bottom: 10px;
  };
`

export const AddLink = styled.a`
  ${cssLink}
  ${({ color }) => {
    if (color) {
      return `color:${color}`
    }
  }}
`

export const FileLink = styled.label`
  ${cssLink}
`

export const TableTitle = styled.p`
  font-weight: bold;
  font-size: 1.2rem;

  @media(max-width: 700px){
    font-size: 16px;
  }
`

export const Divider = styled.div`
  border-bottom: 1px solid #ddd;
  height: 0;
  ${props => props.margin && `margin: ${props.margin}`}
`