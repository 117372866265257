import React from 'react';
import { ModalOverlay, ModalContainer } from './styles';

function Modal({ children, bigger, fixedSize, front, scroll }){

  return(
    <ModalOverlay>
      <ModalContainer
        role = 'modal'
        bigger={bigger}
        fixedSize={fixedSize}
        front={front}
        scroll={scroll}
      >
        {children}
      </ModalContainer>
    </ModalOverlay>
  )
}

export default Modal;