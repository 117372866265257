import { createContext, useState } from "react";
import jwt from "jsonwebtoken";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [logged, setLogged] = useState(!!sessionStorage.getItem('token'));
  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const [isSessionExpired, setIsExpiredSession] = useState(false);


  const signIn = (token) => {
    sessionStorage.setItem('token', token);
    setToken(token);
    setLogged(true);
    setIsExpiredSession(false);
  }

  const signOut = () => {
    sessionStorage.clear();
    setToken(null);
    setLogged(false);
  }

  const checkTokenValidation = () => {
    const decoded = jwt.decode(token);

    if(!decoded){
      setIsExpiredSession(true);
      signOut();

      return false;
    }
    
    const { exp } = decoded;
    const currentTime = Date.now() / 1000;
    if(!exp || exp < currentTime){
      setIsExpiredSession(true);
      signOut();

      return false;
    }

    return true;
  }


  return(
    <AuthContext.Provider
      value = {{
        logged,
        signIn,
        signOut,
        token,
        checkTokenValidation,
        isSessionExpired
      }}
    >
      {children}
    </AuthContext.Provider>
  )
} 


export default AuthContext;