import React from 'react';
import Modal from '../modal';
import Loader from "react-loader-spinner";


function LoadingModal(){
  return(
    <Modal>
      <Loader
        type="ThreeDots"
        color="#2891D5"
        height={10}
        width={50}
      />  
    </Modal>
  )
}

export default LoadingModal;