import React, { useContext } from "react";
import ButtonIcon from "../../../../components/buttonicon";
import OutlineRoundButton from "../../../../components/outlineroundbutton";
import { ItemName } from "../../styles";
import { Column, CompanyItemContainer, IconLink, List, ListItem, Row } from "./styles";
import { FiExternalLink, FiEdit, FiKey } from 'react-icons/fi';
import ApiConfigContext from "../../../../contexts/apiConfigContext";

function CompanyItem({ company, openEdit, index, openTestModal, openKeys}) {
  const { isPointingToDefaultUrl } = useContext(ApiConfigContext);
  
  return(
    <CompanyItemContainer
      role = 'listitem'
      aria-label = {`company-${company.codigoEmpresa}`}
      firstItem = {index === 0}
    >
      <Column>
        <ItemName smaller>
          {company.nomeFantasia} 
        </ItemName>
        <List>
          {company.urls && company.urls.map(url =>(
            <ListItem
              key = {url.aplicativo}
            >
              <OutlineRoundButton
                app = {url.aplicativo}
                onClick = {() => openTestModal(url.url)}
              >
                <FiExternalLink />
                <span>{url.descricao}</span>
              </OutlineRoundButton>
            </ListItem>
          )
            
          )}
        </List>
      </Column>
			<Row>
				<IconLink onClick={openEdit}>
					<FiEdit color="rgba(0,0,0,.7)"/>		
				</IconLink>

        {!isPointingToDefaultUrl &&
          <IconLink onClick={openKeys}>
            <FiKey color="rgba(0,0,0,.7)"/>		
          </IconLink>
        }
			</Row>

    </CompanyItemContainer>
  )
}

export default CompanyItem;