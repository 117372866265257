import styled from "styled-components";


export const Label = styled.label`
  font-weight: bold;
  font-size: 1rem;

  width: 100%;
  margin: 1rem 0 0;
  
  text-align: left;
`

export const AppName = styled.span`
  text-align: left;
  width: 100%;
`

export const Input = styled.input.attrs(props => ({
  as: props.as
}))`
  margin: .5rem 0;
  padding: .5rem;
  border: 1px solid ${props => props.hasError ? 'red':'#ccc'};
  height: 2rem;
  width: 100%;
`

export const DateInput = styled.input`
  border: 1px solid ${props => props.hasError ? 'red':'#ccc'};
  height: 2rem;
  width: 100%;
  margin: .5rem 0;
  padding: .5rem;

`

export const Error = styled.span`
  color: red;
  font-size: .9rem;
`

export const CancelButton = styled.a`
  background: red;
  border-radius: 3px;

  color: #fff;
  font-size: .9rem;

  width: 100%;
  padding: .5rem 0;
  align-self: center;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    cursor: pointer;
  }
`