import styled from "styled-components";


export const ClickableLabel = styled.a`
  font-weight: bold;
  font-size: 0.9rem;
  color: #2891D5;
  margin: .5rem 0 0;

  &:hover{
    cursor: pointer;
  }
`



export const CrtsInput = styled.textarea`
  border: 1px solid ${props => props.hasErrors ? 'red' : '#ccc'};
  padding: .5rem;
  margin: ${props => props.hasErrors ? '0' : '.5rem'} 0 1rem;
  max-height: 200px;
  font-size: .9rem;
  width: 100%;
`

