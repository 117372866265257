import { useCallback, useContext, useEffect, useState } from "react";
import ApiConfigContext from "../../contexts/apiConfigContext";
import { Body, ItemContainer, Label, List, Title } from "./style";
import api from "../../services/api";
import StoragedList from "../../storage/list";
import CloseButton from "../closeButton";
import Modal from "../modal";
import AuthContext from "../../contexts/authContext";
import SearchInput from "../inputsearch";
import Loader from "react-loader-spinner";
import LabelledButton from '../labelledbutton';
import { useHistory } from "react-router-dom";

function GroupPicker({ closeModal, handleItemClick }){
  const [listToShow, setListToShow] = useState(null);
  const [originalList, setOriginalList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { apiUrl } = useContext(ApiConfigContext);
  const { token } = useContext(AuthContext);
  const history = useHistory();
  
  useEffect(() => {
    loadGroups()
  }, []);


  const openSignUp = () => {
    history.push('/cadastro');
  }

  async function loadGroups(){

    const storagedList = StoragedList.getList();

    if(storagedList){
      setListToShow(storagedList);
      setOriginalList(storagedList);
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    try {
      setIsLoading(true)
      const response = await api.directTo(apiUrl).get('/getAllGroups', config);
      const list = response.data.data;
      StoragedList.setList(list)
      setListToShow(list);
      setOriginalList(list);
    } catch {
      return;
    } finally {
      setIsLoading(false);
    }
  }

  const filterListByTerm = useCallback(term => {
    let result = [];
    if (term === '') return setListToShow(originalList);
    originalList.forEach(i => {
      if (i.grupo.toUpperCase().includes(term.toUpperCase())) {
        result.push(i);
      }
    })
    setListToShow(result);
  })

  return(
    <Modal fixedSize>
      <CloseButton handleClick={closeModal}/>
      <Title>Selecione o grupo</Title>

      {listToShow && 
      <>
      <SearchInput
        onChange={event => filterListByTerm(event.target.value)}
        placeholder='Pesquise por grupo' 
      />
      <Body>
        
        <List>
        {listToShow && listToShow.map(item => (
        <ItemContainer onClick={() => handleItemClick(item)}>
          <Label>
            {item.grupo}
          </Label>
        </ItemContainer>
        ))}
        </List>

        
        <LabelledButton
          src="assets/add.png"
          label="Grupo"
          handleClick={openSignUp}
        />

      </Body>
      </>
      }

      {isLoading &&
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={10}
          width={20}
        />}
    </Modal>
  )
}

export default GroupPicker;