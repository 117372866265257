import React, { useState } from 'react';
import { Input, DateInput, Label, Error, CancelButton, AppName } from './styles';
import  MainButton  from '../../../../components/mainbutton'
import Modal from '../../../../components/modal';

function AppSelectionModal({ closeModal, handleAppAdd, handleAppUpdate, apps, appToEdit }){

  const today = new Date();
  const minimumDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2,"0")}-${today.getDate().toString().padStart(2, "0")}`;
  const defaultDate = `${today.getFullYear() + 1}-${(today.getMonth() + 1).toString().padStart(2,"0")}-${today.getDate().toString().padStart(2, "0")}`;

  const [selectedAppValue, setSelectedAppValue] = useState(appToEdit ? appToEdit.aplicativo : null);
  const [selectedDate, setSelectedDate] = useState(appToEdit ? appToEdit.dataValidade : defaultDate);

  const [fillError, setFillError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const sortedApps = (apps) => {
    apps.sort((a, b) => {
      if(parseInt(a.aplicativo) > parseInt(b.aplicativo)){
        return 1
      }else{
        return -1;
      }
    })

    return apps;
  }


  const handleSave = () => {
    
    if(!selectedAppValue || !selectedDate){
      setFillError(true);
      return;
    }

    if(selectedDate <= minimumDate){
      setDateError(true);
      return;
    }

    const selectedApp = apps.find(app => app.aplicativo.toString() === selectedAppValue);

    if(appToEdit){  
      handleAppUpdate({...appToEdit, dataValidade: selectedDate})
    }else{
      handleAppAdd(selectedApp, selectedDate);
    }
    closeModal();
    
  }

  const handleAppSelection = (value) => {
    setSelectedAppValue(value);
    setFillError(false);
  }

  const handleDateSelection = (value) => {
    setSelectedDate(value);
    setDateError(false);
  }

  
  return(
    <Modal>

      <Label
        htmlFor = 'app-input'
      >
        Aplicativo licenciado: 
      </Label>

      {appToEdit ?
      <AppName>{appToEdit.descricao}</AppName>
      : 
      <Input 
        onChange = {event => handleAppSelection(event.target.value)}
        hasError = {fillError && !selectedAppValue}
        as = "select"
        defaultValue = "0"
        id = 'app-input'
      >
        <option 
          disabled 
          value = "0"
          key = "0"
        >
          Selecione um aplicativo
        </option>
        {sortedApps(apps).map(app => (
          <option 
            value = {app.aplicativo}
            key = {app.aplicativo}
          >
            {app.descricao}
          </option>
        ))}
      </Input>}

      <Label
        htmlFor = 'date-input'
      >
        Expira em: 
      </Label>

      <DateInput 
        type = "date"
        min = {minimumDate}
        onChange = {event => handleDateSelection(event.target.value)}
        hasError = {(fillError && !selectedDate) || dateError} 
        defaultValue = {appToEdit ? appToEdit.dataValidade : defaultDate}
        id = 'date-input'
      />

      {dateError && 
        <Error
          role = 'error'
          aria-label = 'date-error'
        >
          Data inválida
        </Error>
      }


      {fillError && 
        <Error
          role = 'error'
          aria-label = 'fill-error'
        >
          Preencha todos os campos.
        </Error>
      }

      <MainButton
        onClick = {handleSave}
        name = 'save-app'
      >
        Salvar
      </MainButton>

      <CancelButton 
        onClick = {closeModal}
        role = 'button'
      >
        Cancelar
      </CancelButton>
      
    </Modal>
  )
}

export default AppSelectionModal;