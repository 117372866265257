import React from 'react';
import Modal from '../modal';
import { Icon, Message } from './styles';

function ResponseModal({ response, icon }){
  return (
    <Modal front>
      <Icon
        src = {`assets/${icon}.png`}
      />
      <Message>{response}</Message>
    </Modal>
  )
}

export default ResponseModal;