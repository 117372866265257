import { useState } from "react";
import Loader from "react-loader-spinner";
import Modal from "../modal";
import { Message, OptionButton, OptionsContainer } from "../modal/styles";

function AppExclusionModal({ deleteApp, showResponseModal, closeModal}){

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmClick = async () => {
    setIsLoading(true);
    try{
      const wasSuccessful = await deleteApp();
      if(wasSuccessful){
        await showResponseModal('success');
      }else{
        await showResponseModal('error');
      }
    }finally{
      setIsLoading(false);
    }

  }
  return(
    <Modal>
      {
        isLoading ?
        (
        <Loader
          type="ThreeDots"
          color="#2891D5"
          height={10}
          width={50}
        /> 
        ):(
        <>
          <Message>Deseja realmente excluir esse aplicativo?</Message>
          <OptionsContainer>
            <OptionButton 
              onClick={handleConfirmClick}
              confirmation
              inGrid
            >
              Confirmar
            </OptionButton>
            <OptionButton
              onClick={closeModal}
              inGrid
            >
              Cancelar
            </OptionButton>
          </OptionsContainer> 
          </>
        )
        
      }
      </Modal>
      
  )
}

export default AppExclusionModal