import styled from "styled-components";

export const Icon = styled.img`
  align-self: center;
  width: 100px;
  margin-bottom: 1rem;
`

export const Message = styled.span`
  font-weight: bold;
  font-size: 1rem;
  align-self: center;
`