
import { ApiConfigProvider } from "./contexts/apiConfigContext";
import { AuthProvider } from "./contexts/authContext";
import Routes from "./routes";

function App() {
  return (
    <AuthProvider>
      <ApiConfigProvider>
        <Routes />
      </ApiConfigProvider>
    </AuthProvider>
  );
}

export default App;
