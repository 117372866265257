import React, { useEffect, useMemo, useState } from 'react';

import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { MdArrowDropDown, MdArrowDropUp, MdSearch } from "react-icons/md"
import GlobalFilter from './globalfilter';
import requests from '../../services/requests';
import moment from "moment-timezone"
import LoadingModal from '../../components/loadingmodal';
import { Container, PageContainer, Table } from './styles';


function VersaoServicos() {
  const [versionsResponse, setVersionsResponse] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    initComponent();
  }, [])


  async function initComponent() {
    try {
      setLoading(true);

      const response = await requests.getVersions();

      if (response.error) {
        setLoading(false);
        return alert('Ops... Não foi possível carregar as versões')
      }

      const tableData = response.versoes.map(item => {
        const dataToDisplay = moment(item.ultimaAtualizacao.replace(/Z/g, '')).format('DD/MM/YYYY HH:mm');

        return {
          col1: item.grupo,
          col2: item.servico,
          col3: item.versao,
          col4: dataToDisplay
        }
      })


      setVersionsResponse(tableData);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }


  const data = useMemo(() => versionsResponse, [versionsResponse])



  const columns = useMemo(() => [
    {
      Header: 'Grupo',
      accessor: 'col1'
    },
    {
      Header: 'Serviço',
      accessor: 'col2'
    },
    {
      Header: 'Versão',
      accessor: 'col3'
    },
    {
      Header: 'Data',
      accessor: 'col4'
    }
  ], [])



  const tableInstance = useTable({ columns: columns, data: data }, useGlobalFilter, useSortBy)



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  } = tableInstance



  return (
    <PageContainer>
      <Container>
        <div>
          <GlobalFilter setGlobalFilter={setGlobalFilter} />
        </div>

        {(!loading && versionsResponse.length > 0) && <Table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <MdArrowDropDown size={12} />
                          : <MdArrowDropUp size={12} />
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>}
      </Container>
      {loading && <LoadingModal />}

      {data.length === 0 ? <span id="noData">Sem versões para mostrar</span> : null}
    </PageContainer>
  )
}

export default VersaoServicos;