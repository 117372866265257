import React from "react"


import {
  Input
} from "./style"


function InputComponent(props) {
  return (
    <Input {...props} />
  );
}

export default InputComponent;