import React from 'react';
import { BoxBottom, BoxContent, BoxMessage, BoxTitle, ButtonConfirm, ButtonNo, Container } from './styles';


const ConfirmDialog = ({ title, message, handleConfirm, handleCancel }) => {
  return (
    <Container>
      <BoxMessage>
        <BoxTitle>
          <h1>{title}</h1>
        </BoxTitle>
        <BoxContent>
          <p>{message}</p>
        </BoxContent>
        <BoxBottom>
          <ButtonNo onClick={handleCancel}>
            NÃO
          </ButtonNo>
          <ButtonConfirm onClick={handleConfirm}>
            SIM
          </ButtonConfirm>
        </BoxBottom>
      </BoxMessage>
    </Container>
  )
}

export default ConfirmDialog;