import { useState, createContext } from "react";

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [groupToUpdate, setGroupToUpdate] = useState(null);
  const [companyToUpdate, setCompanyToUpdate] = useState(null);

  const selectGroup = (group) => {
    setGroupToUpdate(group);
  }

  const clearGroupSelection = () => {
    setGroupToUpdate(null);
  }

  const selectCompany = (company) => {
    setCompanyToUpdate(company)
  }

  const clearCompanySelection = () => {
    setCompanyToUpdate(null);
  }

  

  return(
    <FormContext.Provider
      value = {{
        selectGroup,
        clearGroupSelection,
        groupToUpdate,
        selectCompany,
        clearCompanySelection,
        companyToUpdate,
        
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export default FormContext;