import { useState } from "react";
import Loader from "react-loader-spinner";
import NetWork from "../../utils/network";
import Modal from "../modal";
import UrlTestButton from "../urltestbutton";
import {TestAgainLink, Status, UrlText } from "./styles";
import CloseButton from "../closeButton";

function UrlTestModal({ url, closeModal }){
 
  return(
    <Modal>
      <CloseButton handleClick={closeModal}/>

      <UrlText>
        {url}
      </UrlText>
      
      <UrlTestButton
        url={url}
        width={'50%'}
        
      />

    </Modal>
  )
}

export default UrlTestModal;