import styled from "styled-components";

export const UrlTestButtonContainer = styled.a`
  color: ${props => props.color};
  font-weight: bold;
  font-size: 1rem;

  background: none;
  border: 1px solid ${props => props.color} ;
  border-radius: 3px;
  
  width: ${props => props.width};
  margin: .5rem 0;
  padding: .35rem;
  &:hover{
    cursor: ${props => props.disabled ? 'auto':'pointer' };
  }
`
