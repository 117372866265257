import Format from "../../utils/format";
import ButtonIcon from "../buttonicon";
import { GreenBall, RedBall, TableDataFlex, UsersSefipTableContainer, EmptyListNotice, ResponsiveDataContainer, ResponsiveLabel, TableData, TableHead, TableHeadRow, TableRow, TableTitle } from "./style";


function UsersSefipTable({ users, handleRowClick }) {


  return (
    <UsersSefipTableContainer>

      <TableHeadRow>


        <TableHead>
          #
        </TableHead>

        <TableHead>
          Usuário
        </TableHead>


        <TableHead align="right">
          Ativo
        </TableHead>

      </TableHeadRow>


      {users.map((user, index) => (
        <TableRow
          onClick={() => handleRowClick(user)}
          key={index.toString()}
          whiteRow={index % 2 === 0}
        >
          <TableData smaller>
            {user.user}
          </TableData>

          <TableData >
            {user.userName}
          </TableData>


          <TableDataFlex smaller align="right">
            {user.ativo ? <GreenBall /> : <RedBall />}
          </TableDataFlex>


          <ResponsiveDataContainer>
            <ResponsiveLabel bold>
              {user.user}
            </ResponsiveLabel>

            <ResponsiveLabel bold>
              {user.userName}
            </ResponsiveLabel>


            <ResponsiveLabel bold>
              {user.ativo ? 'Sim' : "Não"}
            </ResponsiveLabel>
          </ResponsiveDataContainer>

          <ButtonIcon
            src="assets/edit.png"
          />
        </TableRow>
      ))}


    </UsersSefipTableContainer>
  )
}

export default UsersSefipTable;