import styled from "styled-components"

export const Input = styled.input.attrs({

})`
  width: 100%;
  padding: 10px 5px;
  border: none;
  outline: none;
  border-bottom: 1px solid #eee;
  
`

