import styled from "styled-components";


export const Container = styled.div`
  padding: 10px;
  width: 100%;
`

export const Lista = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
  overflow: auto;
  max-height: 500px;
  width: 100%;

  ::-webkit-scrollbar {
    margin-right: 15px;
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border: 1px solid #eee;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2891D5;
    border-radius: 10px;
  }

`

export const ItemContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span {
    font-weight: 600;
    margin-bottom: 8px;
  }
`

export const ChaveContainer = styled.div`
  width: 100%;
  display: flex;


`

export const CopyButton = styled.button`
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
`


export const ContainerLinha = styled.li`
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  span {
    padding: 0;
    font-size: 14px;
  }

  :nth-child(odd){
    background: #eee;
  }

`

