import React, { useState } from 'react';
import { useAsyncDebounce } from "react-table"
import { MdSearch } from "react-icons/md"
import { WrapperInput } from './styles';

function GlobalFilter({ setGlobalFilter }) {
  const [value, setValue] = useState('');

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200);


  return (
    <WrapperInput>
      <div>
        <input placeholder='Pesquisar ...' value={value || ''}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
        <MdSearch color={"rgba(0,0,0,.4)"} />
      </div>
    </WrapperInput>
  )
}

export default GlobalFilter;