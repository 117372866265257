import styled, { keyframes } from "styled-components"


const fadeIn = keyframes`
  from{
    opacity: 0
  }to{
    opacity: 1
  }
`

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.7);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} .3s forwards;
`

export const BoxMessage = styled.div`
  width: 400px; 

  background: #fff;
`

export const BoxTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid #eee;
  padding: 10px;  

  >h1{
    font-weight: bold;
    font-size: 16px;
  }
`

export const BoxContent = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex: 1;
  >p{
    font-weight: 100;
    font-size: 14px;
    line-height: 24px;
  }
`

export const BoxBottom = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  padding: 12px 8px;
`

export const ButtonConfirm = styled.button`
  color: #fff;
  background: #2991D6;
  border: none;  
  padding: 6px 20px;
  border-radius: 2px;
  font-size: .75rem;
  cursor: pointer;
  transition: all .2s;
  &:hover{
    opacity: .8;
  }
`

export const ButtonNo = styled.button`
  color: #fff;
  background: #da2c38;
  border: none;  
  padding: 6px 20px;
  border-radius: 2px;
  font-size: .75rem;
  cursor: pointer;
  &:hover{
    opacity: .8;
  }
`