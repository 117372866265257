export default class GeneralUtils {
  static fileToBase64(element) {
    if (!element) return;
    return new Promise((resolve) => {
      const fileReader = new FileReader();

      var base64data;

      fileReader.readAsDataURL(element.files[0]);

      fileReader.onload = () => {
        base64data = fileReader.result;
        resolve(base64data);
      }
    })
  }



  static extractImageSize(base64) {
    if (!base64) return;
    return new Promise((resolve) => {
      var image = new Image();
      image.src = base64;
      image.onload = () => {
        resolve({ width: image.width, height: image.height })
      }
    })

  }

  static validateCNPJ(cnpj){
    if (cnpj)
      cnpj = cnpj.replace(/[^\d]+/g, '');

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999" ||
      cnpj === undefined)
      return false;

    // Valida DVs
    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);

    let sum = 0;
    let pos = length - 7;
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(0))
      return false;

    length = length + 1;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;
    for (let i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(1))
      return false;

    return true;
  }

  static formatGroupObject(group){
    return {
      grupo: group.groupName,
      grupoEmpresa: group.grupoEmpresa,
      empresas: group.empresas,
      imageSarApp: group.imageSarApp,
      apps: group.apps.map(app => {
        return {
          aplicativo: parseInt(app.aplicativo),
          descricao: parseInt(app.aplicativo) === 1 ? 'SarApp' : parseInt(app.aplicativo) === 2 ? 'HKPonto' : 'HKRonda',
          dataValidade: `${app.datavalidade}T00:00:00.000Z`,
        }
      })
    }
  }


  static formatCompanyObject(company){
    let urls = [];
    if (company.urlSarApp) {
      urls.push({ aplicativo: '1', descricao: 'SarApp', url: company.urlSarApp })
    }

    if (company.urlHKPonto) {
      urls.push({ aplicativo: '2', descricao: 'HKPonto', url: company.urlHKPonto })
    }

    if (company.urlHKRonda) {
      urls.push({ aplicativo: '3', descricao: 'HKRonda', url: company.urlHKRonda })
    }

    return {
      codigoEmpresa: parseInt(company.codigoempresa),
      nomeFantasia: company.nomefantasia,
      cnpj: company.cnpj,
      razaoSocial: company.razaosocial,
      grupoEmpresa: company.grupoempresa,
      logo: company.logo,
      urls: urls
    }
  }
}