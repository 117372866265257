import React, { useContext, useEffect, useState } from 'react';
import { Field, Formik } from 'formik';

import Loader from "react-loader-spinner";


import {
  Button,
  CompanyFormContainer,
  SignUpFormFieldLabel,
  SignUpFormikField,
  ContainerButtons,
  DisableUserLabel,
  EnableUserLabel
} from './styles';

import AuthContext from '../../../../contexts/authContext';

import LoadingModal from '../../../../components/loadingmodal';
import ResponseModal from '../../../../components/responsemodal';

import Modal from '../../../../components/modal';

import CloseButton from '../../../../components/closeButton';
import useFormUserSefip from './useFormUserSefip';




function UserSefipForm({ handleClose, userData, edit, codGrupo }) {
  const { createUser, loading, updatePass, disableUser, isRequestErrorModalVisible, isSuccessModalVisible, userSchema, enableUser } = useFormUserSefip();
  const [alterForm, setAlterForm] = useState(false);


  const _handleSubmit = async (values) => {
    if (!edit) {
      const { userName, pass } = values;
      return createUser(userName, pass, codGrupo, handleClose);
    }

    updatePass(userData.user, values.pass);
  }



  return (
    <Modal bigger>
      <CloseButton handleClick={handleClose} />

      {loading &&
        <LoadingModal />}


      {isSuccessModalVisible.show &&
        <ResponseModal
          icon='checked'
          response={`Operação concluída`}
        />
      }

      {isRequestErrorModalVisible.show &&
        <ResponseModal
          icon='sad'
          response={isRequestErrorModalVisible.message}
        />
      }



      <Formik
        initialValues={{ userName: userData ? userData.userName : '', pass: userData ? '******' : '' }}
        validationSchema={userSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { resetForm }) => {
          _handleSubmit(values);
        }}
      >
        {({ isSubmitting, errors, setFieldValue, values, setErrors, resetForm, handleSubmit, handleChange }) => (
          <CompanyFormContainer
            role="form"
            onSubmit={handleSubmit}
            autocomplete="off"
          >


            <SignUpFormFieldLabel htmlFor="userName">Usuário</SignUpFormFieldLabel>
            <SignUpFormikField
              autocomplete="off"
              disabled={edit}
              type="text"
              name="userName"
              autofocus
              haserrors={errors.userName}
              onChange={event => {
                if (errors.userName)
                  setErrors({ ...errors, userName: null });
                setFieldValue('userName', event.target.value);
              }
              }
              value={values.userName}
            />
            {errors.userName ? <span style={{ textAlign: 'left', color: 'tomato', fontSize: 10 }}>{errors.userName}</span> : null}



            <SignUpFormFieldLabel htmlFor="pass">Senha</SignUpFormFieldLabel>
            <SignUpFormikField
              autocomplete="off"
              type="password"
              name="pass"
              onChange={event => {
                if (errors.pass)
                  setErrors({ ...errors, pass: null });
                setAlterForm(true);
                setFieldValue('pass', event.target.value);
              }
              }
              value={values.pass}
            />

            {errors.pass ? <span style={{ textAlign: 'left', color: 'tomato', fontSize: 10 }}>{errors.pass}</span> : null}



            <ContainerButtons>
              {(!edit || alterForm) && <Button
                type="submit"
                aria-label='save-company'
                align={'flex-start'}
              >
                {loading ?
                  <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={10}
                    width={20}
                  />
                  : 'SALVAR'}
              </Button>}

              {edit && (
                <>
                  {userData.ativo && <DisableUserLabel onClick={() => disableUser(userData, handleClose)}>Desativar Usuário</DisableUserLabel>}
                  {!userData.ativo && <EnableUserLabel onClick={() => enableUser(userData, handleClose)}>Ativar Usuário</EnableUserLabel>}
                </>
              )}
            </ContainerButtons>

          </CompanyFormContainer>
        )}

      </Formik>
    </Modal>
  )
}


export default UserSefipForm;